import './../../scss/style.scss'
import 'v-calendar/dist/style.css'
import { createApp } from 'vue'
import App from './App.vue'
import { store } from '@/application/store'
import { router } from '@/application/router'
import { i18n } from '@/application/localization'
import '../../application/validation/rules.js'
import VCalendar from 'v-calendar'
import AppDatePicker from '@/application/components/inputs/AppDatePicker.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { recaptchaSiteKey } from '@/application/utils/recaptcha.js'

const app = createApp(App)
app.component('app-date-picker', AppDatePicker)
app.use(VCalendar, {})
app.use(store)
app.use(router)
app.use(i18n)
app.use(VueReCaptcha, { siteKey: recaptchaSiteKey, loaderOptions: { autoHideBadge: true } })

app.mount('#app')
