<template>
  <div id="app">
    <router-view></router-view>
    <modals-container></modals-container>
  </div>
</template>

<script>
import { onMounted, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { getCookie } from '@/application/utils/cookie.js'
import { useLogout } from '@/application/composables/logout.js'
import axios from 'axios'
import { ModalsContainer } from 'vue-final-modal'
import { includeTrackingScripts } from '@/application/utils/tracking-scripts'

export default {
  name: 'App',
  components: {
    ModalsContainer,
  },
  setup() {
    const store = useStore()
    const { logout } = useLogout()
    const refreshSessionCookie = () => {
      const token = getCookie('session_token')
      if (getCookie('session_token') !== undefined) {
        store.commit('changeAuthStatus', token)
        axios.defaults.headers.common.Authorization = token
      } else {
        logout()
      }
    }
    onMounted(() => {
      refreshSessionCookie()
      includeTrackingScripts()
    })
    const isLoggedIn = computed(() => store.getters.getLoggedIn)
    watch(isLoggedIn, () => {
      refreshSessionCookie()
    })
  },
}
</script>
