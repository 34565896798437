<template>
  <v-date-picker
    v-model="date"
    :min-date='minDate'
    :locale="$i18n.locale"
    :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
    :masks="{ input: $i18n.locale === 'en' ? 'MMMM DD, YYYY' : 'DD.MM.YYYY'}"
    class="inline-block h-full"
    :is-range="isRange"
  >
    <template v-slot="{ inputValue, togglePopover, updateValue }">
      <div class="form-control-clear">
        <input
          :value="inputValue"
          class="form-control"
          @focus="togglePopover()"
          @change="togglePopover()"
        />
        <button class="clear" @click.prevent="updateValue('')" v-if="inputValue !== ''"></button>
      </div>
    </template>
  </v-date-picker>
</template>

<script>

import { ref, watch } from 'vue'

export default {
  name: 'AppDatePicker',
  emits: ['clear-data'],
  props: {
    value: String,
    isRange: {
      type: Boolean,
      default: () => false,
    },
    minDate: {
      type: Date,
      default: () => null,
    },
    changeHandler: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props) {
    const dateRef = ref(props.value)
    watch(dateRef, (newDate) => {
      props.changeHandler(newDate)
    })

    return {
      date: dateRef,
    }
  },
}
</script>
